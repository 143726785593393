<template>
  <div class="row">
    <EmptyState
      :to="{ name: 'create-candidate' }"
      v-if="showBlankState"
      icon="jbsmd-candidate"
      :message="$gettext('You don\'t have any candidates')"
      :customButtonText="$gettext('Create a candidate')"
    ></EmptyState>
    <div v-show="!showBlankState" class="col-md-12">
      <ContentHeader :title="$gettext('Candidates')">
        <template #description>
          {{ candidateLimitMessage }}
        </template>
        <template #options>
          <router-link
            :to="{ name: 'subscription' }"
            class="btn btn-outline-primary mr-2"
          >
            <i class="fas fa-arrow-up mr-2"></i>
            <translate>Increase your limit</translate></router-link
          >
          <router-link
            :disabled="hasReachedProductLimit"
            class="btn btn-primary align-self-baseline"
            to="create-candidate"
            ><i class="fas fa-user-plus mr-2"></i>
            <translate>Add candidate</translate></router-link
          >
        </template>
      </ContentHeader>

      <AdvancedTable
        store="candidates"
        :fields="fields"
        :advance-filter-enabled="true"
      >
        <template #cell(full_name)="data">
          <router-link
            :to="{
              name: 'candidate-details',
              params: { candidateId: data.item.id, candidate: data }
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(created_at)="data"
          ><FormattedDate
            :date="data.item.created_at"
            :format="{ dateStyle: 'medium' }"
        /></template>
        <template #cell(actions)="data">
          <b-button
            @click="handleView(data)"
            variant="info"
            class="btn btn-info btn-icon btn-sm"
            v-b-tooltip.hover
            :title="$gettext('View')"
            ><i class="fa fa-eye"></i
          ></b-button>
          <b-button
            @click="handleDelete(data)"
            variant="danger"
            class="btn btn-info btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('Delete')"
            ><i class="fa fa-times"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { msgBoxConfirm } from "@/utils/modal";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import AdvancedTable from "@/components/AdvancedTable";
import FormattedDate from "@/components/FormattedDate";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: { EmptyState, ContentHeader, AdvancedTable, FormattedDate },
  data: () => ({}),
  computed: {
    ...mapGetters("candidates", [
      "productUsage",
      "productLimit",
      "hasReachedProductLimit",
      "showBlankState"
    ]),
    ...mapGetters("app", ["isFetching"]),
    fields() {
      return [
        {
          key: "full_name",
          sortable: true,
          label: this.$gettext("Name")
        },
        { key: "email", sortable: true, label: this.$gettext("Email") },
        {
          key: "phone_number",
          sortable: true,
          label: this.$gettext("Phone Number")
        },
        {
          key: "applications_count",
          sortable: true,
          label: this.$gettext("#Applications")
        },
        { key: "location.address", label: this.$gettext("City") },
        {
          key: "created_at",
          sortable: true,
          label: this.$gettext("Date")
        },
        { key: "actions", label: this.$gettext("Actions") }
      ];
    },
    candidateLimitMessage() {
      const translated = this.$ngettext(
        "%{ usage } candidate over your limit of %{ limit }",
        "%{ usage } candidates over your limit of %{ limit }",
        this.productUsage
      );
      return this.$gettextInterpolate(translated, {
        usage: this.productUsage,
        limit: this.productLimit
      });
    }
  },
  methods: {
    ...mapActions("candidates", ["delete"]),
    handleDelete({ item }) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          return this.delete(item.id);
        }
      });
    },
    handleView({ item }) {
      this.$router.push({
        name: "candidate-details",
        params: { candidateId: item.id, candidate: item }
      });
    }
  }
};
</script>
